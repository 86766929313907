::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #2faae1;
}

::-webkit-scrollbar-thumb:hover {
  background: #2faae1;
}


/* Confirmation Component Radio Button Label padding only for first line fix*/
.mantine-Radio-label{
  display: inline-block;
}
.mantine-Modal-content {
  height: 400; /* Set the desired height here */
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #c4c4c4;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.3) 20%, rgba(255, 255, 255, 0.7) 60%, rgba(255, 255, 255, 0));
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: "";
}
@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}




.chat::before {
  content: '';
  position: absolute;
  visibility: visible;
  bottom: 0px;
  left: -8px;
  border: 10px solid transparent;
  border-bottom: 10px solid #4b5563;
}

.right-chat::after {
  content: '';
  position: absolute;
  visibility: visible;
  bottom: 0px;
  right: -8px;
  border: 10px solid transparent;
  border-bottom: 10px solid #d1d5db;
  clear: both;
}